@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Noto Sans KR", sans-serif;
}

.font-serif {
  font-family: "Noto Serif KR", serif;
}
